import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { getParentOrigin } from '../../../util/api';
import style from './bill-index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../common/loading/loading';
import { useAncillaryFiles } from '../../../api/ancillary-files';
import { extractNumbersFromString } from '../../../util/util';
import { useSessionIdByNumber } from '../../../api/parliament';

/**
 * Overview for the bills of a session
 */
export function BillIndex() {
  const [searchParams] = useSearchParams();
  const parliament = searchParams.get('parliament') ?? '42nd';
  const session = searchParams.get('session') ?? '4th';

  const parNumber = extractNumbersFromString(parliament);
  const sessNumber = extractNumbersFromString(session);
  const sessionId = useSessionIdByNumber(parNumber, sessNumber);

  const { ancillaryFiles, loading } = useAncillaryFiles(sessionId ?? 0);
  const billsFiles = ancillaryFiles?.filter((file) => {
    return file.ancillaryAttributesByFileId.nodes?.[0].billIndex;
  });
  const baseUrl = `${getParentOrigin()}/parliamentary-business/overview/${parliament}-parliament/${session}-session`;

  return (
    <Loader loading={loading}>
      <div className={style.billOverview}>
        <div className={style.groupLinks}>
          <div><a href={`${baseUrl}/bills/first-reading`} target='_parent'>First Reading</a></div>
          <div><a href={`${baseUrl}/bills/amended`} target='_parent'>Amended</a></div>
          <div><a href={`${baseUrl}/bills/third-reading`} target='_parent'>Third Reading</a></div>
        </div>
        <div className={style.documentLinks}>
          {billsFiles?.map((acf) => {
            const paths = acf.filePath.split('/'); // pattern is /ldp/{par}{sess}/extraPath
            paths.splice(0, 3);
            let filePath;
            if (paths.length) {
              filePath = paths.join('/') + '/' + acf.fileName;
            } else {
              filePath = acf.fileName;
            }
            return (
              <div key={acf.fileName}>
                <a href={`${baseUrl}/file/${filePath}`} target='_parent'>
                  <FontAwesomeIcon icon={faFile} /> {acf.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Loader>
  )
}
