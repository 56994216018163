import React from 'react';
import style from '../../pages/committee-calendar/committee-calendar.module.css';
import { DropdownItem } from '../dropdown-list/dropdown-item';
import { type HansardInfo, type MeetingDocument } from '../../pages/committee-about/committee-interfaces';
import { type CommitteeTranscript } from '../../../interfaces/committee-about';
import { onVideoLink } from '../../../util/util';

export interface TranscriptLinksProp {
  hansardData?: CommitteeTranscript
  commDoc?: MeetingDocument
  hasMeetingDocuments?: boolean
  docUrl?: string
  prefix?: string
}

/**
 * List of meeting transcript links
 * @param props react properties
 * @returns a list of meeting transcript links
 */
export function TranscriptLinks(props: TranscriptLinksProp) {
  const hansardInfoObj = props.hansardData?.committeeTranscriptAttributeByFileId;
  const hansardFileAtributeObj = props.hansardData as HansardInfo;
  const fileName = hansardFileAtributeObj?.fileName;
  const filePath = hansardFileAtributeObj?.filePath;
  const htmlUrl = `hansard-content${filePath}/${fileName}`;
  const prefix = props.prefix;
  const audioUrl = hansardInfoObj?.audioLink;
  const PDFPath = hansardInfoObj?.pdfLink;
  const PDFUrl = `hansard-content${PDFPath}`;
  const commDocObj = props.commDoc?.committeeFileAttributeByFileId;

  return (
    <div>
      {(fileName ?? audioUrl ?? PDFPath ?? commDocObj ?? props.hasMeetingDocuments)
        ? <div className={style.box}>
            {fileName ?? audioUrl ? <div className={style.light}>{prefix}</div> : <></> }
            {fileName
              ? <div className={style.transcriptLink}>
                  <DropdownItem title='HTML' url={htmlUrl}></DropdownItem>
                </div>
              : <></>
            }
            {PDFPath
              ? <div className={style.transcriptLink}>
                  <DropdownItem title='Official Report (PDF)' url={PDFUrl}></DropdownItem>
                </div>
              : <></>
            }
            {audioUrl
              ? <div className={style.transcriptLink}>
                  <DropdownItem title='Audio' url={audioUrl} hasBaseUrl={true} onClick={onVideoLink}></DropdownItem>
                </div>
              : <></>
            }
            {(commDocObj ?? props.hasMeetingDocuments)
              ? <div className={`${(fileName ?? audioUrl) ? style.transcriptLink : style.document}`}>
                  <DropdownItem title='Meeting Documents' url={props.docUrl ?? ''}></DropdownItem>
                </div>
              : <></>
            }
          </div>
        : <></>}
    </div>
  );
}
