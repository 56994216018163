import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { gql } from '../../../__generated__';
import { useQuery } from '@apollo/client';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { get } from '../../../api/api';
import { capitalize, formatPhoneNumber, getImageUrl, sendTitleToParent, getRelevantCommittees, extractNumbersFromString } from '../../../util/util';
import type { CommitteeLinkName, CommitteeMembership } from '../../../util/util';
import { ContactInformationGrid } from '../../common/contact-information-grid/contact-information-grid';
import { Loader } from '../../common/loading/loading';
import { SideNav } from '../../common/side-nav/side-nav';
import style from './mla-bio.module.css';
import { StringContentRender } from '../../common/string-content-render/string-content-render';

export const GET_MLA = gql(`
query GetMLA(
  $firstname: String!
  $lastname: String!
  $parliament: Int!
) {
  allMembers(condition: { lastName: $lastname, firstName: $firstname }) {
    nodes {
      id
      firstName
      lastName
      electionYears
      memberParliamentsByMemberId(
        filter: { parliamentByParliamentId: { number: { in: [$parliament] } } }
        first: 1
      ) {
        nodes {
          id
          aboutMember
          active
          govEmail
          isHonourable
          isDoctor
          isCounsel
          legislativeEmail
          legislativeOfficeId
          officePhone
          parliamentByParliamentId {
            endDate
            active
            number
            session: sessionsByParliamentId(
              last: 1
              condition: { active: true }
            ) {
              nodes {
                number
                annotation
              }
            }
          }
          image: imageByMediumImageId {
            path
            description
          }
          party: partyByPartyId {
            abbreviation
          }
          constituency: constituencyByConstituencyId {
            offices: constituencyOfficesByConstituencyId(first: 5, condition: { active: true }) {
              nodes {
                active
                address
                city
                constituencyId
                email
                fax
                name
                phoneNumber
                postalcode
                province
                tollFreePhone
              }
            }
            name
          }
          legOffice: legislatureOfficeByLegislativeOfficeId {
            email
            fax
            name
            phone
            roomNumber
            legBuilding: legislatureBuildingByBuildingId {
              address
              city
              postalcode
              province
              name
            }
          }
          ministry: memberRolesByMemberParliamentId(
            condition: { active: true }
            first: 5
          ) {
            nodes {
              endDate
              roleByRoleId {
                title
                email
                details
              }
            }
          }
        }
      }
    }
  }
  allParliaments(condition: { active: true }, orderBy: NUMBER_DESC, first: 1) {
    nodes {
      number
      annotation
      id
      sessionsByParliamentId(orderBy: NUMBER_DESC, first: 1) {
        nodes {
          number
          id
          annotation
        }
      }
    }
  }
}
`);

/**
 * checks if a surname contains a space
 * @param surname surname to format
 * @returns formatted name
 */
function formatSurname(surname: string) {
  if (surname.startsWith('de ')) {
    return formatDutchSurname(surname);
  } else if (surname.includes(' ')) {
    return formatSurnameWithSpaces(surname);
  } else if (surname.includes("'")) {
    return formatSurnameWithApostrophy(surname);
  }
  return capitalize(surname);
}

/**
 * checks if a last name contains the duth word for the
 * @param surname name to format
 * @returns capitalized last name
 */
function formatDutchSurname(surname: string) {
    const dutchSurname = surname.split(' ')[1];
    const capitalized = capitalize(dutchSurname);
    return `de ${capitalized}`;
}

/**
 * formats a name to capitals if it contains spaces
 * @param surname name with spaces
 * @returns formatted name
 */
function formatSurnameWithSpaces(surname: string) {
  const split = surname.split(' ');
  return split.map((part) => {
    return capitalize(part);
  }).join(' ');
}

/**
 * formats a name with an apostrophy (D'Arcy)
 * @param surname name to format
 * @returns formatted name
 */
function formatSurnameWithApostrophy(surname: string) {
  const split = surname.split("'");
  return split.map((part) => {
    return capitalize(part);
  }).join("'");
}

/**
 * The find mla by constituency page
 * @returns React Element for the main Find mla by constituency
 */
export function MLABio() {
  const [committees, setCommittees] = useState<CommitteeLinkName[]>();
  const [indexLinks, setIndexLinks] = useState<Array<Record<string, any>>>();
  const [searchParams] = useSearchParams();

  /**
    The codes below was written as a stop-gap solution to accommodate
    members name with hypen on either their firstname or lastname
    to ensure their bio page data are still pulled correctly.
   */
  const name = (searchParams.get('name') ?? '');
  const nameSplit = name.split('-');
  let firstNameA: string, lastNameA: string;
  let firstNameB = ''; let lastNameB = '';

  firstNameA = capitalize(nameSplit[1]); lastNameA = formatSurname(nameSplit[0]);

  if (nameSplit.length === 3) {
    firstNameA = capitalize(`${nameSplit[1]}-${nameSplit[2]}`);
    lastNameA = formatSurname(nameSplit[0]);
    firstNameB = capitalize(nameSplit[2]);
    lastNameB = formatSurname(`${nameSplit[0]}-${nameSplit[1]}`);
  }

  const parliament = extractNumbersFromString(searchParams.get('parliament') ?? '');
  const { loading, data: dataA } = useQuery(GET_MLA, { variables: { lastname: lastNameA, firstname: firstNameA, parliament } });
  const { data: dataB } = useQuery(GET_MLA, { variables: { lastname: lastNameB, firstname: firstNameB, parliament } });
  const data = dataA?.allMembers?.nodes.length ? dataA : dataB;
  // end of the stop-gap code

  const mlaInfo = data?.allMembers?.nodes?.[0];
  const mla = data?.allMembers?.nodes?.[0]?.memberParliamentsByMemberId?.nodes?.[0];
  const ministries = mla?.ministry?.nodes?.filter((ministry) =>
    ministry.endDate === null || ministry.endDate >= new Date());

  const url = getImageUrl(mla?.image?.path);
  const fName = (mla?.isHonourable ? 'Hon. ' : '') + (mla?.isDoctor ? 'Dr. ' : '') + mlaInfo?.firstName;
  const lName = mla?.isCounsel ? mlaInfo?.lastName + ', K.C.' : mlaInfo?.lastName;
  const nameTitle = `MLA: ${fName} ${lName}`;

  const parliamentById = mla?.parliamentByParliamentId;
  const isPageValid = mla && parliamentById?.active;
  const parNumber = parliamentById?.number;
  const isCurrentParliament = data?.allParliaments?.nodes?.[0]?.number === parliament;

  let aboutMember = mla?.aboutMember;
  aboutMember = aboutMember?.replace(/(?:\r\n|\r|\n)/g, '<br />').replace(/<br \/><br \/>/g, '</p><p>');
  if (!aboutMember?.startsWith('<p>')) {
    aboutMember = `<p>${aboutMember}`;
  } if (!aboutMember?.endsWith('</p>')) {
    aboutMember = `${aboutMember}</p>`;
  }

  useEffect(() => {
    const loadCommittees = async () => {
      const committeeMembership = await get<CommitteeMembership>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees/committee-membership/parliament/by-number/${parNumber}`);
      const relevantCommsA = getRelevantCommittees(committeeMembership, firstNameA, lastNameA);
      const relevantCommsB = getRelevantCommittees(committeeMembership, firstNameB, lastNameB);
      const relevantCommittees = relevantCommsA ?? relevantCommsB;
      setCommittees(relevantCommittees);
    }
    void loadCommittees();
  }, [parNumber]);

  useEffect(() => {
    const loadIndexLinks = async () => {
      const indexLinks = await get(`${getAPIBaseUrl()}/hdms/members-index/${mlaInfo?.id}/${mla?.id}`);
      setIndexLinks(indexLinks);
    }
    void loadIndexLinks();
  }, [mlaInfo?.id, mla?.id]);

  if (fName && lName) {
    sendTitleToParent(nameTitle);
  }
  if (!isPageValid && !loading) {
    sendTitleToParent('Page not found');
    return <div>The requested page could not be found.</div>;
  }

  return (
    <Loader loading={loading} notFound={!mla}>
      <div className={style.mlaBio}>
        <div>
          <div className={style.mlaHeader}>
            <img width='200px' src={url} alt={mla?.image?.description}></img>
            <div className={style.mlaInfo}>
              <div>
                {ministries
                ? <div>{ministries.map(ministry => ministry.roleByRoleId?.title).join(', ')}</div>
                : <></>}
                <div>{mla?.constituency?.name}</div>
                <div>
                  Elected {mlaInfo?.electionYears}
                </div>
                <div>
                  {mla?.party?.abbreviation}
                </div>
              </div>
              <div></div>
              {isCurrentParliament
              ? <>
              <div>
                <LabeledAddress
                  header='Office'
                  name={mla?.legOffice?.legBuilding?.name}
                  city={mla?.legOffice?.legBuilding?.city}
                  province={mla?.legOffice?.legBuilding?.province}
                  postalCode={mla?.legOffice?.legBuilding?.postalcode}
                />
                {ministries
                ? ministries.map((ministry, index) =>
                  ministry.roleByRoleId?.email
                  ? <a key={index} href={`mailto:${ministry.roleByRoleId.email}`}>
                    {ministry.roleByRoleId?.email}
                  </a>
                  : <></>)
                : <></>}
                <ContactInformationGrid phone={formatPhoneNumber(mla?.legOffice?.phone)} fax={formatPhoneNumber(mla?.legOffice?.fax)} />
              </div>
              <div>
                {mla?.constituency?.offices.nodes?.map((office, index) => {
                  return (
                    <React.Fragment key={index}>
                      <LabeledAddress
                        header='Constituency'
                        name={office?.address}
                        city={office?.city}
                        province={office?.province}
                        postalCode={office?.postalcode}
                      />
                      {index === 0 ? <a href={`mailto:${mla?.legislativeEmail}`}>{mla?.legislativeEmail}</a> : <></>}
                      <ContactInformationGrid phone={formatPhoneNumber(office?.phoneNumber)} fax={formatPhoneNumber(office?.fax)} tollFreePhone={formatPhoneNumber(office.tollFreePhone)} />
                    </React.Fragment>
                  )
                })}
              </div>
              </>
              : <></>}
            </div>
          </div>
          <StringContentRender content={aboutMember} contentStyle={style.mlaAbout} />
        </div>
        <div className={style.sideBar}>
          {indexLinks && indexLinks?.length > 0
          ? <SideNav headerText='Parliamentary proceedings'>
            {indexLinks.map((indexLink, index) => {
              return <a key={index} href={`${getParentOrigin()}/hansard-content${indexLink.linkPath}`} target='_top'>{indexLink.title}</a>
            })}
          </SideNav> : <></>}
          {committees && committees?.length > 0
            ? <SideNav headerText='Committees'>
              {committees.map((committee, index) => {
                return <a key={index} href={committee.link} target='_top'>{committee.name}</a>
              })}
            </SideNav>
            : <></>}
        </div>
      </div>
    </Loader>
  )
}

interface LabeledAddressProps {
  header: string
  name: string | undefined
  city: string | undefined
  province: string | undefined
  postalCode: string | undefined
}
/**
 * an adress with a label
 * @param props labeledAddress
 */
export function LabeledAddress(props: LabeledAddressProps) {
  return (
    <div className={style.labeledAddress}>
      <h3>{props.header}:</h3>
      <div>{props?.name}</div>
      <div>{props?.city}, {props?.province} {props?.postalCode}</div>
    </div>
  )
}
