import React from 'react'
import { Header } from '../../common';
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';
import { getParentOrigin } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import style from './executive-council.module.css';
import { useCurrentParliament } from '../../../api/parliament';

/**
 * graphql query to load the amount of members per party and the total number of constituencies
 */
export const GET_EXECUTIVE_COUNCIL = gql(`
query GetExecutiveCouncil($parliament: Int!) {
  allMemberRoles(
    condition: { active: true }
    filter: {
      roleByRoleId: { roleTypeByTypeId: { id: { in: 1 } } }
      memberParliamentByMemberParliamentId: {
        parliamentId: { in: [$parliament] }
      }
    }
  ) {
    nodes {
      membersParliament: memberParliamentByMemberParliamentId {
        id
        member: memberByMemberId {
          firstName
          lastName
          isCounsel
          isHonourable
        }
      }
      role: roleByRoleId {
        title
      }
    }
  }
}
`);

/**
 * The party standings page
 * @returns React Element for the party standings
 */
export function ExecutiveCouncil() {
	const { currentParliament: parliament, loading: parLoading } = useCurrentParliament();
	const { loading, data } = useQuery(GET_EXECUTIVE_COUNCIL, { variables: { parliament: parliament?.number ?? 0 } });
	const ministers = [...data?.allMemberRoles?.nodes ?? []];
	/**
	 * formats the council data to be alphabetical based on last name and have the premier always be first
	 */
	const formatCouncilData = () => {
		ministers?.sort((a, b) => {
			const aLastName = a?.membersParliament?.member?.lastName ?? '';
			const bLastName = b?.membersParliament?.member?.lastName ?? '';
			return aLastName.localeCompare(bLastName);
		});
		const premierIndex = ministers?.findIndex((member) => {
			return member.role?.title === 'Premier and President of Executive Council';
		}) ?? 0;
		const premier = ministers?.[premierIndex];
		ministers?.splice(premierIndex, 1)
		if (premier) ministers?.unshift(premier);
	};
	formatCouncilData();

	return (
		<>
			<Loader loading={loading && parLoading} notFound={!ministers?.length}>
				<div className={style.executiveCouncil}>
					<Header>MLA</Header>
					<Header>Responsibility</Header>
					{
						ministers?.map((minister, index) => {
							const member = minister?.membersParliament?.member;
							const fName = member?.isHonourable ? 'Hon. ' + member?.firstName : member?.firstName;
							const lName = member?.isCounsel ? member?.lastName.toUpperCase() + ', K.C.' : member?.lastName.toUpperCase();
							return (
								<React.Fragment key={index}>
									<a href={`${getParentOrigin()}/members/${parliament?.number}${parliament?.annotation}-Parliament/${member?.lastName}-${member?.firstName}`} target="_parent">
										{`${lName}, ${fName}`}
									</a>
									<div>{minister?.role?.title}</div>
								</React.Fragment>
							);
						})
					}
				</div>
			</Loader>
		</>
	)
}
